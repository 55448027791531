import TopicCard from "../topics/topicCard";
import AutoPlayCarousel from "components/carousel/carousel";
import data from "../../exampleItems/exampleData.json";
import "./home.scss";
import Hero from "components/baners/hero";

export default function HomePage(params: any) {
  return (
    <div className="wrap">
      <Hero />

      <div className="container home_container">
        <div className="home_topic_container">
          {data.map((item) => (
            <TopicCard item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

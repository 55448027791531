// TopicCard.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import "./articlesCard.scss";

interface TopicCardProps {
  item: {
    id: string;
    img: string;
    title: string;
    date: string;
  };
}

const MAX_TITLE_LENGTH = 28; // Максимальна довжина для Title (ваш вибір)

const truncateTitle = (title: string) => {
  if (title.length > MAX_TITLE_LENGTH) {
    return title.substring(0, MAX_TITLE_LENGTH) + "...";
  }
  return title;
};

const TopicCard: React.FC<TopicCardProps> = ({ item }) => (
  <div
    className="topics_card_container"
    style={{ backgroundImage: `url(${item.img})` }}
  >
    <NavLink to={`/article/${item.id}`} className="topics_link">
      <div className="topics_card_desc_container">
        <div className="title_and_fire">
          <h3 className="topics_card_title">{truncateTitle(item.title)}</h3>
        </div>
        <p className="date_text">{item.date}</p>
      </div>
    </NavLink>
  </div>
);

export default TopicCard;

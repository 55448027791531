import { useNavigate } from "react-router-dom";
import "./style.scss";
import data from "../exampleItems/exampleData.json";
import AutoPlayCarousel from "components/carousel/carousel";
export default function Hero() {
  const banerCarusel = data.slice(0, 5);

  return (
    <div className="baner_container">
      <div
        className="first_baner"
        style={{ backgroundImage: `url(/dealer.webp)` }}
      >
        <div className="text_container"></div>
      </div>
      <AutoPlayCarousel items={banerCarusel} />

      <div
        className="second_baner"
        style={{ backgroundImage: `url(/shuffle.webp)` }}
      >
        <h1 className="title">
          Skill in Every Shuffle. Discover the World of Poker
        </h1>
      </div>
    </div>
  );
}
